import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './history.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import config from "../config/config";

function Chathistory() {
  const [chatHistory, setChatHistory] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchChatHistory = async () => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/dashboard/history`);
        setChatHistory(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchChatHistory();
  }, []);

  const handleViewChat = (phonenumber) => {
    navigate(`/chat-history/${phonenumber}`);
  };

  return (
    <div className='manager'>
      <h4 className='chefs-textsmall'>Chat history</h4>
      <div className='container mt-3'>
       <div>
         <Link className="textuse " to="/Chat-history"><button className='userbtn mt-2'><FontAwesomeIcon className="iconhistory" icon={faUsers} /> User</button></Link>
         <Link className="textuse" to="/Admin-history"> <button className='Adminbtn mt-2'> <FontAwesomeIcon className="iconhistory" icon={faUser} /> Admin</button> </Link> 
        </div>
      </div>
        <div className='row mt-2'>
          <div className="eventsdiv ">
            <div className="table-responsive">
              <table className="table text-center">
                <thead>
                  <tr className="tabletext">
                    <th>S.NO</th>
                    <th>Name</th>
                    <th>Mobile number</th> 
                    <th>View chat</th>
                  </tr>
                </thead>
                <tbody className="table">
                  {chatHistory.map((event, index) => (
                    <tr key={index} className="mt-3">
                      <td>{event.id}</td>
                      <td>{event.name}</td>
                      <td>{event.phonenumber}</td>
                      <td>
                        <button 
                          type="button" 
                          className='historyview bg-warning' 
                          onClick={() => handleViewChat(event.phonenumber)}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
 
  );
}

export default Chathistory;
