import React, { useState, useEffect, useCallback } from "react";
import "../style/addish.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../config/config";
import axios from "axios";
import { PulseLoader } from "react-spinners";

const EditFxMenu = ({ selectedItem , handleClose }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedFoodItems, setSelectedFoodItems] = useState([]);
  let [color] = useState("#ffa500");
  const [menus, setMenus] = useState([]);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   axios
  //     .get(`${config.apiBaseUrl}/menus/get_all_web_menu_details`)
  //     .then((response) => {
  //       setCategories(response.data);
  //       handleSelectionForEdit(response.data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching the data", error);
  //       toast.error("Error fetching menu details, please try again later.");
  //     });
  // }, []);
  
  const handleSelectionForEdit = useCallback((data) => {
    if (!selectedItem) return; // Prevent fetch if selectedItem is not set

    fetch(`${config.apiBaseUrl}/menus/get_overall_fixed_menus_items`)
      .then((response) => response.json())
      .then((data) => {
        const filteredMenus = data.find(item => item.Fixed_menu_name === selectedItem);
        setMenus(filteredMenus);
        console.log(data);

        const allMenuNamesSet = new Set();

        if (filteredMenus && filteredMenus.items) {
          filteredMenus.items.forEach(item => {
            allMenuNamesSet.add(item.items_name);
          });
        }

        setSelectedFoodItems(Array.from(allMenuNamesSet));
      })
      .catch((error) => {
        console.error('Error fetching menus:', error);
      });
  }, [selectedItem]);
  
  useEffect(() => {
    axios
      .get(`${config.apiBaseUrl}/menus/get_all_web_menu_details`)
      .then((response) => {
        setCategories(response.data);
        handleSelectionForEdit(response.data); // Use handleSelectionForEdit here
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the data", error);
        toast.error("Error fetching menu details, please try again later.");
      });
  }, [handleSelectionForEdit]); 

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory((prev) => (prev === categoryId ? null : categoryId));
  };

  const handleCheckboxChange = (menuId) => {
    setSelectedFoodItems((prevSelected) => {
      if (prevSelected.includes(menuId)) {
        return prevSelected.filter((id) => id !== menuId);
      } else {
        return [...prevSelected, menuId];
      }
    });
  };


  

  /* const handleSelectionForEdit = (objItem) => {
   
  }; */




  useEffect(() => {
    console.log('All Categories:', categories);
  }, [categories]);

  const handleInsert = async (menu_id) => {
    try {
      const response = await axios.post(`${config.apiBaseUrl}/menus/insert_fixedmneu_fooditems`, {
        fooditems: selectedFoodItems,
        foodid: menu_id,
      });

      if (response.status === 201) {
        toast.success("Food items inserted successfully");
        setSelectedFoodItems([]); 
      }
    } catch (error) {
      if (error.response) {
        toast(error.response.data.error || 'An unexpected error occurred');
      } else {
        toast('Server is not responding. Please try again later.');
      }
    }
  };

  const closeModal = () => {
    handleClose();
  };

  return (
    <>
      <div className="menus-edit">
        <ToastContainer />
        {loading ? (
          <div className="spinner-container">
            <div className="sweet-loading">
              <PulseLoader
                color={color}
                loading={loading}
                size={12}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          </div>
        ) : (
          <div className="modal-dialog modal-xl" id="EditMenuModal" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Edit Fixed Menu</h5>
              </div>
              <div className="modal-body">
                <div className="Edit-name">{selectedItem}</div>
                <div className="list-items">
                  {menus && menus.items ? (
                    menus.items.map((item, index) => (
                      <div key={index} className="list-of-items">
                        {item.items_name}
                      </div>
                    ))
                  ) : (
                    <div>No items available</div>
                  )}

                </div>
                <div className="row d-flex justify-content-center menu-list-cls">
                  <div>
                    {categories.map((category) => (
                      <div key={category.category_Id} className="col-lg-12 col-12 mt-2">
                        <div
                          className="beavergecatagry"
                          onClick={() => handleCategoryClick(category.category_Id)}
                        >
                          <div className="row">
                            <div className="col-lg-11 col-md-11 col-11">
                              <h6 className="catagaryname">{category.category_name}</h6>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-1 col-1 d-flex justify-content-end chevronbutton">
                              <FontAwesomeIcon
                                className="chevronmenusicon"
                                icon={faChevronDown}
                              />
                            </div>
                          </div>
                        </div>
                        {selectedCategory === category.category_Id && (
                          <div
                            className={`row subdivfixedmenu ${selectedCategory === category.category_Id ? "show" : ""}`}
                          >
                            {category.sub_categories.map((subCategory) => (
                              <div key={subCategory.sub_category_id} className="sub-catagry">
                                <h5 className="sub-catagry-name">{subCategory.sub_category_name}</h5>
                                <div className="row">
                                  {subCategory.menus.map((menu) => (
                                    <div key={menu.Menu_Id} className="col-lg-3 col-md-3 col-sm-4 col-6 mb-2">
                                      <input
                                        className="form-check-input checbox"
                                        type="checkbox"
                                        checked={selectedFoodItems.includes(menu.Menu_Name)}
                                        onChange={() => handleCheckboxChange(menu.Menu_Name)}
                                      />
                                      {menu.Menu_Name}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary btn-modal-danger" onClick={() => closeModal('EditMenuModal')}>
                  Close
                </button>
                <button type="button" className="btn btn-primary btn-modal-succ" onClick={handleInsert}>
                  Save changes
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditFxMenu;
