import React, { useEffect, useState, useRef } from "react";
import "../style/menus.css";
import axios from "axios";
import config from "../config/config";
import { PulseLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faXmark } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Menus = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [CreatemenuOpen, setCreatemenuOpen] = useState(false);
  const [insertCategories, setInsertCategories] = useState([]);
  const [categorySelected, setCategorySelected] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [subCategorySelected, setSubCategorySelected] = useState("");
  const [dishName, setDishName] = useState("");
  const [menuType, setMenuType] = useState("");
  const [Uoms, setUoms] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [ingredientData, setIngredientData] = useState([]);


  let [color] = useState("#ffa500");
  const menuRef = useRef();

  const handleMenuTypeChange = (e) => {
    setMenuType(e.target.value);
  };

  const handleSaveDish = async () => {
    if (!subCategorySelected || !dishName || !menuType) {
      toast("Please fill in all fields");
      return;
    }

    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/menus/insertMenuDetails`,
        {
          subCategorySelected,
          dishName,
          menuType,
        }
      );

      if (response.status === 201) {
        toast("Menu details inserted successfully");
        setCreatemenuOpen(false);
        setDishName("");
        setMenuType("");
        setSubCategorySelected("");
      } else {
        toast("Failed to insert menu details");
      }
    } catch (error) {
      console.error("Error inserting menu details:", error);
      toast("An error occurred while saving the dish");
    }
  };

  useEffect(() => {
    axios
      .get(`${config.apiBaseUrl}/menus/get_fullmenu_subcatagorizes_list`)
      .then((response) => {
        setInsertCategories(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the categories!", error);
        toast.error("Error fetching categories, please try again later.");
      });
  }, []);

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setCategorySelected(categoryId);

    const selectedCat = insertCategories.find(
      (cat) => cat.category_id === parseInt(categoryId)
    );
    setSelectedSubCategory(selectedCat ? selectedCat.sub_categories : []);
    setSubCategorySelected("");
  };

  const handleSubCategoryChange = (e) => {
    setSubCategorySelected(e.target.value);
  };

  const handleDishNameChange = (e) => {
    setDishName(e.target.value);
  };

  useEffect(() => {
    axios
      .get(`${config.apiBaseUrl}/menus/get_all_web_menu_details`)
      .then((response) => {
        setCategories(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the data", error);
        setLoading(false);
        toast.error("Error fetching menu details, please try again later.");
      });
  }, []);

  const handleCategoryClick = (categoryId) => {
    if (selectedCategory === categoryId) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(categoryId);
    }
  };

  // const handlecardmenu = () => {
  //   setCreatemenuOpen(!CreatemenuOpen);
  // };

  const handleinsermenuOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setCreatemenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleinsermenuOutside);
    return () => {
      document.removeEventListener("mousedown", handleinsermenuOutside);
    };
  }, []);

  useEffect(() => {
    axios
      .get(`${config.apiBaseUrl}/menus/get_ingredients_unit`)
      .then((response) => {
        setUoms(response.data);
        console.log("Fetched UOMs:", response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the UOMs!", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${config.apiBaseUrl}/menus/Get_Vendor_ingredient_details`)
      .then((response) => {
        setIngredientData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleTypeChange = (index, value) => {
    const newIngredients = [...ingredients];
    newIngredients[index].type = value;

    const selectedTypeData = ingredientData.find(
      (type) => type.Vendor_id === parseInt(value)
    );
    if (selectedTypeData) {
      newIngredients[index].availableIngredients = selectedTypeData.ingredients;
    } else {
      newIngredients[index].availableIngredients = [];
    }

    setIngredients(newIngredients);
  };

  const handleItemChange = (index, value) => {
    const newIngredients = [...ingredients];
    newIngredients[index].name = value;
    setIngredients(newIngredients);
  };

  const handleUOMChange = (index, value) => {
    const newIngredients = [...ingredients];
    newIngredients[index].uom = value;
    setIngredients(newIngredients);
  };

  const handleIngredientChange = (index, key, value) => {
    const newIngredients = [...ingredients];
    newIngredients[index][key] = value;
    setIngredients(newIngredients);
  };

  const addIngredient = () => {
    setIngredients([
      ...ingredients,
      { type: "", name: "", quantity: "", uom: "", availableIngredients: [] },
    ]);
  };

  const removeIngredient = (index) => {
    const updatedIngredients = [...ingredients];
    updatedIngredients.splice(index, 1);
    setIngredients(updatedIngredients);
  };

  const saveIngredients = () => {
    const savePromises = ingredients.map((ingredient) => {
      const data = {
        p_Name: ingredient.name,
        p_UOM: ingredient.uom,
        p_Vendor_id: ingredient.type,
        p_Quantity_value: parseFloat(ingredient.quantity),
      };

      return axios
        .post(`${config.apiBaseUrl}/menus/insertItemIngredients`, data)
        .then((response) => {
          console.log("Ingredient saved:", response.data);
          toast.success(`Ingredient ${ingredient.name} saved successfully!`);
        })
        .catch((error) => {
          console.error("Error saving ingredient:", error);
          toast.error("Error saving ingredient. Please try again.");
        });
    });

    Promise.all(savePromises)
      .then(() => {
        setIngredients([]);
      })
      .catch((error) => {
        console.error("Error occurred during the saving process:", error);
      });
  };

  const handlecdardmenu = async (menuId) => {
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/menus/Get_Edit_Menu_Details`,
        { menu_id: menuId }
      );
      if (response.status === 200 && response.data.length > 0) {
        const menuDetails = response.data[0].menu_details[0]; // Assuming menu_details is an array
        
        // Set the details in the state
        setDishName(menuDetails.Menu_Name);
        setMenuType(menuDetails.Menu_Type.toLowerCase()); // Veg or Non-veg
        setCategorySelected(menuDetails.CATEGORY_ID);
        setSubCategorySelected(menuDetails.sub_category_ID);

        // Set the ingredients
        const ingredientsData = response.data[0].menu_details.map((detail) => ({
          type: detail.Vendor_Id,
          name: detail.Name,
          quantity: detail.Quantity_value,
          uom: detail.UOM,
          availableIngredients: [], // This can be fetched separately or set accordingly
        }));
        setIngredients(ingredientsData);
        
        setCreatemenuOpen(true); // Open the edit form modal
      } else {
        toast.error("Failed to fetch menu details");
      }
    } catch (err) {
      toast.error(err.response?.data?.error || "An unexpected error occurred");
    }

    setCreatemenuOpen(true); 
  };

  return (
    <div className="menus">
      <ToastContainer />
      <h5 className="menuheadx mt-2">Menus</h5>
      {CreatemenuOpen && (
        <div className="menunamecreate-poplay">
          <div ref={menuRef} className="menunamecreate">
            <h5 className="adddishead">Create menus</h5>
            <div className="dishdivchoose">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                  <h6 className="catagris">Menu Type</h6>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="inlineRadio1"
                      name="categoryType"
                      value="veg"
                      checked={menuType === "veg"} // Bind to state
                      onChange={handleMenuTypeChange}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      Veg
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="inlineRadio2"
                      name="categoryType"
                      value="non-veg"
                      checked={menuType === "non-veg"} // Bind to state
                      onChange={handleMenuTypeChange}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      Non-veg
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                  <h6 className="catagris">Main categories</h6>
                  <select
                    className="form-select"
                    value={categorySelected} // Bind to state
                    onChange={handleCategoryChange}
                  >
                    <option value="" disabled>
                      Choose Main categories
                    </option>
                    {insertCategories.map((category) => (
                      <option
                        key={category.category_id}
                        value={category.category_id}
                      >
                        {category.category_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-4  col-md-4 col-sm-12 col-12">
                  <h6 className="catagris">Sub categories</h6>
                  <select
                    className="form-select"
                    value={subCategorySelected} // Bind to state
                    onChange={handleSubCategoryChange}
                  >
                    <option value="" disabled>
                      Choose Sub categories
                    </option>
                    {selectedSubCategory.map((subcategory) => (
                      <option key={subcategory.sub_category_ID} value={subcategory.id}>
                        {subcategory.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row form-group">
                <div className=" col-12">
                  <h6 className="ingred mt-4">Enter Dish Name</h6>
                  <input
                    type="text"
                    value={dishName} // Bind to state
                    placeholder="Enter dish name"
                    className="form-group-input"
                    onChange={handleDishNameChange}
                  />
                </div>
              </div>
              <h6 className="ingred mt-4">Enter Dish Name</h6>
              <div className="scrolldivmenus">
              {ingredients.map((ingredient, index) => (
               
                <div className="row form-group mt-3" key={index}>
                  <div className="col-lg-3 col-6 mt-1">
                    <select
                      className="form-select"
                      value={ingredient.type}
                      onChange={(e) => handleTypeChange(index, e.target.value)}
                    >
                      <option value="" disabled>
                        Ingredient Type
                      </option>
                      {ingredientData.map((ingredientType) => (
                        <option
                          key={ingredientType.Vendor_id}
                          value={ingredientType.Vendor_id}
                        >
                          {ingredientType.Type}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-3 col-6 mt-1">
                    <select
                      className="form-select"
                      value={ingredient.name}
                      onChange={(e) => handleItemChange(index, e.target.value)}
                      disabled={!ingredient.availableIngredients.length}
                    >
                      <option value="" disabled>
                        Ingredient Name
                      </option>
                      {ingredient.availableIngredients.map(
                        (availableIngredient) => (
                          <option
                            key={availableIngredient.Ingredient_Name}
                            value={availableIngredient.Name}
                          >
                            {availableIngredient.Ingredient_Name}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className="col-lg-3 col-6 mt-1">
                    <input
                      type="text"
                      value={ingredient.quantity}
                      className="form-group-input"
                      onChange={(e) =>
                        handleIngredientChange(
                          index,
                          "quantity",
                          e.target.value
                        )
                      }
                      placeholder="Quantity"
                    />
                  </div>
                  <div className="col-lg-2 col-5 mt-1">
                    <select
                      className="form-select"
                      value={ingredient.uom}
                      onChange={(e) => handleUOMChange(index, e.target.value)}
                    >
                      <option value="" disabled>
                        Choose Unit
                      </option>
                      {Uoms.map((unit) => (
                        <option key={unit.UOM} value={unit.UOM}>
                          {unit.UOM}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-1 col-1 mt-1 p-0 d-flex justify-content-center">
                    <FontAwesomeIcon
                      className="pt-3 remove-btn"
                      onClick={() => removeIngredient(index)}
                      icon={faXmark}
                    />
                  </div>
                </div>
             
              ))}
                 </div>
              <div className="d-flex justify-content-center mt-2">
                <button className="addbutton" onClick={addIngredient}>
                  Add Ingredient
                </button>
                <button
                  className="saveincredite"
                  onClick={() => {
                    saveIngredients();
                    handleSaveDish();
                  }}
                >
                  Update Menus
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {loading ? (
        <div className="spinner-container">
          <div className="sweet-loading">
            <PulseLoader
              color={color}
              loading={loading}
              size={12}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </div>
      ) : (
        <div className="row mt-4 d-flex justify-content-center">
          {categories.map((category) => (
            <div key={category.category_Id} className="col-lg-10 col-12 mt-2">
              <div
                className="beavergecatagry"
                onClick={() => handleCategoryClick(category.category_Id)}
              >
                <div className="row">
                  <div className="col-lg-11 col-md-11 col-11 ">
                    <h6 className="catagaryname">{category.category_name}</h6>
                  </div>
                  <div className="col-1 d-flex justify-content-end chevronbutton">
                    <FontAwesomeIcon
                      className=" chevronmenusicon"
                      icon={faChevronDown}
                    />
                  </div>
                </div>
              </div>
              {selectedCategory === category.category_Id && (
                <div
                  className={`row subdiv ${
                    selectedCategory === category.category_Id ? "show" : ""
                  }`}
                >
                  {category.sub_categories.map((subCategory) => (
                    <div
                      key={subCategory.sub_category_id}
                      className="sub-catagry"
                    >
                      <h5 className="sub-catagry-name">
                        {subCategory.sub_category_name}
                      </h5>
                      <div className="row">
                        {subCategory.menus.map((menu) => (
                          <div
                            key={menu.Menu_Id}
                            className="col-lg-2 col-md-3 col-sm-4 col-6 mb-2"
                          >
                            <button
                              className="btn btn-light menu-button"
                              onClick={() => handlecdardmenu(menu.Menu_Id)}
                            >
                              {menu.Menu_Name}
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
