import React, { useState, useEffect } from "react";
import "../style/orders.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../config/config";

export const Upcommingorders = () => {
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(
          `${config.apiBaseUrl}/dashboard/getevents`
        );
        setEvents(response.data);
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchEvents();
  }, []);

  const OrderPerPage = 8;
  const totalPages = Math.ceil(events.length / OrderPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const indexOfLastEmail = currentPage * OrderPerPage;
  const indexOfFirstEmail = indexOfLastEmail - OrderPerPage;
  const currentEvent = events.slice(indexOfFirstEmail, indexOfLastEmail);

  return (
    <div className="orders">
      <ToastContainer />
      <div className="row">
        <div className="col-6">
          <h5 className="orders-texth">Upcoming Orders</h5>
        </div>
      </div>
      <div className=" mt-3">
        <div className="table-responsive eventsdiv mt-1 ">
          <table className="table  ">
            <thead className="">
              <tr className="tabletext">
                <th>Name</th>
                <th>Event Type</th>
                <th>start Date</th>
                <th>End Date</th>
                <th>Client Number</th>
                <th>Venue</th>
                <th className="text-center">Menu</th>
              </tr>
            </thead>
            <tbody>
              {currentEvent.map((event, index) => (
                <tr key={index} className="mt-3">
                  <td>{event.Client_Name}</td>
                  <td>{event.Event_Name}</td>
                  <td>{event.start_date}</td>
                  <td>{event.end_date}</td>
                  <td>{event.Client_Contact_Number}</td>
                  <td>{event.Event_Address}</td>
                  <td className="text-center">
                    <button className="viewmenubtns">
                      <a href={event.file_path} target="_blank" className="viewmenulink" rel="noopener noreferrer"
>
                        View Menu
                      </a>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="col-12 d-flex justify-content-end">
            <div>
              <span>
                Page {currentPage} of {totalPages}
              </span>
            </div>
            <div>
              <FontAwesomeIcon
                onClick={handlePreviousPage}
                className="arrorrleft-icon"
                icon={faAngleLeft}
              />
              <FontAwesomeIcon
                onClick={handleNextPage}
                icon={faAngleRight}
                className={` arrorright-icon ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
