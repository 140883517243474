import React, { useState, useEffect } from "react";
import "../style/dashboard.css";
import management from '../../assets/Management.png';
import top from '../../assets/top.png';
import menu from '../../assets//Frame 1.png';
import fixded from '../../assets/Availablemanager.png';
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import config from "../config/config";
import CountUp from 'react-countup'; // Import CountUp for animation
const Dashboard = () => {
  const [events, setEvents] = useState([]); // Initialize as an empty array
  const [currentPage, setCurrentPage] = useState(1);
  const [employee, setEmployee] = useState({ count: 0 });
  const [totalitemsCount, setvTotalitemsCount] = useState({ full_menu_count: 0 });
  const [eventCounts, setEventCounts] = useState({ upcoming_event_count: 0, total_event_count: 0 });
  // Consolidated effect for loading counts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [eventCountResponse, fullMenuResponse, employeeCountResponse] = await Promise.all([
          axios.get(`${config.apiBaseUrl}/dashboard/geteventcount`),
          axios.get(`${config.apiBaseUrl}/dashboard/getfullmenucount`),
          axios.get(`${config.apiBaseUrl}/dashboard/getEmployeecount`)
        ]);
        setEventCounts(eventCountResponse.data);
        setvTotalitemsCount(fullMenuResponse.data);
        setEmployee(employeeCountResponse.data);
      } catch (err) {
        console.error("Error fetching dashboard counts:", err);
      }
    };
    fetchData();
  }, []);
  const OrderPerPage = 6;
  const totalPages = Math.ceil(events.length / OrderPerPage);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const indexOfLastEmail = currentPage * OrderPerPage;
  const indexOfFirstEmail = indexOfLastEmail - OrderPerPage;
  const currentEvent = events.slice(indexOfFirstEmail, indexOfLastEmail);
  useEffect(() => {
    axios
      .get(`${config.apiBaseUrl}/dashboard/upcomingevents`)
      .then(response => {
        if (Array.isArray(response.data)) {
          setEvents(response.data);
        } else {
          console.error('Expected array but got', typeof response.data);
          setEvents([]);
        }
      })
      .catch(error => {
        console.error('There was an error fetching the events list!', error);
        setEvents([]);
      });
  }, []);
  return (
    <div className="dashboard">
      <h1 className="dashstart">Hello, Admin</h1>
      <div className="p-2">
        <h5 className="teammangers">Team managers</h5>
        <div className="row">
          <div className="col-sm-6 col-12 col-lg-3 p-2">
            <div className="card card-backgroundimg">
              <div className="card-body">
                <div className="dashprofile">
                  <img className="managementimg" src={fixded} alt="Profile" />
                </div>
                <p className="textmanagemnt1">Total manager</p>
                <p className="cardcounts">
                  <CountUp end={employee.count} duration={2} />
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-12 col-lg-3 p-2">
            <div className="card card-backgroundimg">
              <div className="card-body">
                <div className="dashprofile">
                  <img className="managementimg" src={menu} alt="Profile" />
                </div>
                <p className="textmanagemnt2">
                  Total Food Items
                  <p className="cardcounts2">
                    <CountUp end={totalitemsCount.full_menu_count} duration={2} />
                  </p>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-12 col-lg-3 p-2">
            <div className="card card-backgroundimg">
              <div className="card-body">
                <div className="dashprofile">
                  <img className="managementimg" src={management} alt="Profile" />
                </div>
                <p className="textmanagemnt3">Upcoming events</p>
                <p className="cardcounts3">
                  <CountUp end={eventCounts.upcoming_event_count} duration={2} />
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-12 col-lg-3 p-2">
            <div className="card card-backgroundimg">
              <div className="card-body">
                <div className="dashprofile">
                  <img className="managementimg" src={top} alt="Profile" />
                </div>
                <p className="textmanagemnt4">Total Events</p>
                <p className="cardcounts4">
                  <CountUp end={eventCounts.total_event_count} duration={2} />
                </p>
              </div>
            </div>
          </div>
        </div>
        <h5 className="upcoming-events">Upcoming events</h5>
        <div className="eventsbg mt-3">
          <div className="table-responsive">
            <table className="table text-center">
              <thead>
                <tr className="tabletext">
                  <th>Order id</th>
                  <th>Name</th>
                  <th>Event Date</th>
                  <th>Function Name</th>
                  <th>Client number</th>
                </tr>
              </thead>
              <tbody className="table p-4">
                {currentEvent.length > 0 ? (
                  currentEvent.map((event, index) => (
                    <tr key={index}>
                      <td className="mt-5">{event.Event_ID}</td>
                      <td className="mt-5">{event.Client_Name}</td>
                      <td className="mt-5">{event.Start_Date}</td>
                      <td className="mt-3">{event.Event_Name}</td>
                      <td className="mt-5">{event.Client_Contact_Number}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No upcoming events</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="d-flex justify-content-end">
              <div>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
              </div>
              <div>
                <FontAwesomeIcon onClick={handlePreviousPage} className="arrorrleft-icon" icon={faAngleLeft} />
                <FontAwesomeIcon onClick={handleNextPage} icon={faAngleRight} className={`arrorright-icon ${currentPage === totalPages ? "disabled" : ""}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;






