import React, { useState, useEffect, useRef } from "react";
import "../style/addish.css";
import "../style/Editfixedmenu.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { PulseLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../config/config";
import axios from "axios";
import { Link } from "react-router-dom";
import EditFxMenu from "./Editfixedmenu.js"

function Fixedmenus() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [menus, setMenus] = useState([]);
  const [isOpen, setIsOpen] = useState({});
  const [FixedmenuOpen, setFixedmenuOpen] = useState(false);
  const [dishname, setDishname] = useState("");
  const [loading, setLoading] = useState(true);
  const menuRef = useRef();
  let [color] = useState("#ffa500");
  const [show, setShow] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const handleShow = (itemName) => {
    setSelectedItem(itemName);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  }
  
 


  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setFixedmenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetch(`${config.apiBaseUrl}/menus/get_overall_fixed_menus_items`)
      .then((response) => response.json())
      .then((data) => {
        setMenus(data);
        setLoading(false);
        const initialIsOpen = data.reduce((acc, menu) => {
          acc[menu.Fixed_menu_name] = false;
          return acc;
        }, {});
        setIsOpen(initialIsOpen);
      });
  }, []);

  const toggleAccordion = (menuName) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [menuName]: !prevState[menuName],
    }));
  };

  const handleMenuSave = () => {
    if (dishname.trim() === "") {
      alert("Menu name cannot be empty");
      return;
    }

    fetch(`${config.apiBaseUrl}/menus/insert_fixedmenus/${dishname}`, {
      method: "POST",
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success("Fixed menu created successfully");
          fetch(`${config.apiBaseUrl}/menus/get_overall_fixed_menus_items`)
            .then((response) => response.json())
            .then((data) => {
              setMenus(data);
              setFixedmenuOpen(false);
              setDishname("");
            });
        } else {
          toast.error("Failed to create the Fixed menu");
        }
      })
      .catch((error) => {
        toast.error("An error occurred while creating the menu");
      });
  };

  useEffect(() => {
    axios
      .get(`${config.apiBaseUrl}/menus/get_all_web_menu_details`)
      .then((response) => {
        setCategories(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the data", error);
        setLoading(false);
        toast.error("Error fetching menu details, please try again later.");
      });
  }, []);

  const handleCategoryClick = (categoryId) => {
    if (selectedCategory === categoryId) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(categoryId);
    }
  };

  const handleDelete = async (Menu_Id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete the whole fixed menu?");
    if (!confirmDelete) { return; }
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/menus/Delete_Fixed_Menu`,
        { menu_id: Menu_Id }
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        fetch(`${config.apiBaseUrl}/menus/get_overall_fixed_menus_items`)
          .then((response) => response.json())
          .then((data) => {
            setMenus(data);
          });
      } else {
        toast.error("Failed to delete the menu.");
      }
    } catch (error) {
      toast.error(error.response?.data?.error || "An unexpected error occurred");
    }
  };

  return (
    <div className="menus">
      <ToastContainer />
      <h5 className="menuheadx mt-2">Fixed Menus</h5>
      <div className="addlistdiv">
        <Link to="/Addfixedmenus">
          <button className="Adddishbtn">Add Fixed Menu</button>
        </Link>
      </div>
      {FixedmenuOpen && (
        <div className="popup-overlay">
          <h4 className="Team-management">Add Fixed New Menus</h4>
          <div className="row d-flex justify-content-center mt-3">
            <div className="col-12">
              <input
                type="text"
                value={dishname}
                onChange={(e) => setDishname(e.target.value)}
                placeholder="Enter New Fixed Menus Name"
                className="form-group-input"
              />
            </div>
          </div>
          <div className="row mt-4 d-flex justify-content-center overflow-scroll">
            {categories.map((category) => (
              <div key={category.category_Id} className="col-lg-12 col-12 mt-2">
                <div
                  className="beavergecatagry"
                  onClick={() => handleCategoryClick(category.category_Id)}
                >
                  <div className="row">
                    <div className="col-lg-11 col-md-11 col-11 ">
                      <h6 className="catagaryname">{category.category_name}</h6>
                    </div>
                    <div className="col-1 d-flex justify-content-end chevronbutton">
                      <FontAwesomeIcon
                        className="chevronmenusicon"
                        icon={faChevronDown}
                      />
                    </div>
                  </div>
                </div>
                {selectedCategory === category.category_Id && (
                  <div
                    className={`row subdiv ${selectedCategory === category.category_Id ? "show" : ""
                      }`}
                  >
                    {category.sub_categories.map((subCategory) => (
                      <div key={subCategory.sub_category_id} className="sub-catagry">
                        <h5 className="sub-catagry-name">
                          {subCategory.sub_category_name}
                        </h5>
                        <div className="row">
                          {subCategory.menus.map((menu) => (
                            <div
                              key={menu.Menu_Id}
                              className="col-lg-2 col-md-3 col-sm-4 col-6 mb-2"
                            >
                              <button className="btn btn-light menu-button">
                                {menu.Menu_Name}
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="button-groupadd">
            <button className="insert-name-savebtn" onClick={handleMenuSave}>
              Save
            </button>
          </div>
        </div>
      )}
      {loading ? (
        <div className="spinner-container">
          <div className="sweet-loading">
            <PulseLoader
              color={color}
              loading={loading}
              size={12}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </div>
      ) : (
        <div className="row mt-3">
          {menus.map((menu) => (
            <div key={menu.Fixed_menu_id} className="col-lg-6 col-md-6 col-12 mt-3">
              <div className="generalacardination">
                <div className="row">
                  <div className="text-start col-lg-11 col-md-10 col-10">
                    <h6 className="headtexthelp">{menu.Fixed_menu_name}</h6>
                  </div>
                  <div className="col-lg-1 col-md-2 col-2 chevronbutton ">
                    <FontAwesomeIcon
                      className={`chevron ${isOpen[menu.Fixed_menu_name] ? "rotate" : ""
                        }`}
                      icon={
                        isOpen[menu.Fixed_menu_name]
                          ? faChevronDown
                          : faChevronRight
                      }
                      onClick={() => toggleAccordion(menu.Fixed_menu_name)}
                      aria-expanded={isOpen[menu.Fixed_menu_name]}
                    />
                  </div>
                </div>
                <div
                  className={`accordion-collapse collapse ${isOpen[menu.Fixed_menu_name] ? "show" : ""
                    }`}
                >
                  <div className="accadtext-body">
                    {menu.items.map((item, index) => (
                      <div key={item?.items_id || index} className="row">
                        <div className="col-lg-12 col-md-12 col-12">
                          <p className="fixedmenu-textdata">
                            {item && (
                              <>
                                <svg
                                  width="13"
                                  height="15"
                                  className="bulleticonfixedmenu"
                                  viewBox="0 0 13 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M3.92807 14.1218C3.79103 14.0132 3.75828 13.8184 3.85217 13.6708L7.91747 7.28189C7.98864 7.17003 7.98864 7.02712 7.91747 6.91507L3.85217 0.526192C3.75828 0.378555 3.79103 0.183978 3.92807 0.0751433C4.05242 -0.0234704 4.22826 -0.0234704 4.35262 0.0751433L12.8707 6.83084C13.0431 6.9675 13.0431 7.22927 12.8707 7.36612L4.35262 14.1218C4.22826 14.2204 4.05242 14.2204 3.92807 14.1218ZM4.08176 2.87172L0.55495 0.0747647C0.493056 0.0257418 0.41621 0 0.341634 0C0.266869 0 0.192294 0.0255526 0.1304 0.0747647C-0.00834038 0.183599 -0.0403281 0.377798 0.0535535 0.527138L4.1181 6.91507C4.19059 7.02807 4.19059 7.17116 4.1181 7.28208L0.0535535 13.6698C-0.0403281 13.8192 -0.00834038 14.0134 0.1304 14.1222C0.254188 14.2204 0.42908 14.2204 0.55495 14.1222L4.0776 11.3271L6.77026 7.09829L4.08176 2.87172Z"
                                    fill="#E18722"
                                  />
                                </svg>
                                {item.items_name}
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                    ))}
                    <div className="row ">
                      <div className="col-lg-12 col-md-12 col-12 p-0 d-flex justify-content-end">
                        <FontAwesomeIcon
                          className="fixedmenudeletebtn"
                          icon={faTrashCan}
                          onClick={() => handleDelete(menu.Fixed_menu_id)}
                        />
                        <FontAwesomeIcon
                          className="fixedmenueditbtn"
                          icon={faPenToSquare}
                          onClick={() => handleShow(menu.Fixed_menu_name)}
                        />

                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {show && (
                          <div className="modal fade show custom-backdrop" style={{ display: 'block' }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <EditFxMenu selectedItem={selectedItem} handleClose={handleClose}/>
                          </div>
                        )}
        </div>
      )}
    </div>
  );
}

export default Fixedmenus;
