import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './history.css';
import { useParams } from 'react-router-dom';
import config from "../config/config";

const ChatHistoryPage = () => {
  const { phonenumber } = useParams();
  const [chatHistory, setChatHistory] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchChatHistory = async () => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/dashboard/get_chat_history/${phonenumber}`);
        setChatHistory(response.data);
      } catch (error) {
        setError(error);
      }
    };

    fetchChatHistory();
  }, [phonenumber]);

  if (error) {
    return <div className="Error">Error: {error.message}</div>;
  }

  return (
    <div className="chef">
      <p className="chefs-textsmall"> User Chat History</p>
      <div className="row">
        <div className="col-lg-1 col-md-1 col-0"></div>
        <div className="col-lg-10 col-md-10 col-12">
          <div className="mt-4">
            {chatHistory.map((chat, index) => (
              <div className="ChatMessage" key={index}>
                <div className="Message">
                  {chat.message}
                  <div className="Timestamp">{chat.message_timestamp}</div>
                </div>
                <div className="Response">
                  {chat.response}
                  <div className="Timestampuser">{chat.response_timestamp}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-lg-1 col-md-1 col-0"></div>
      </div>
    </div>
  );
};

export default ChatHistoryPage;
