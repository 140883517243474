import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import "../style/vendors.css";
import uplode from "../management/uplodeimg.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import config from "../config/config";
import { PulseLoader } from "react-spinners";
import defaultImage from "../../assets/Ellipse.png";

export const Vendors = () => {
  const [addManagerOpen, setAddManagerOpen] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [type, setType] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [vendorDetails, setVendorDetails] = useState([]);
  const [vendorTypes, setVendorTypes] = useState({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editVendor, setEditVendor] = useState(null);
  const chefRef = useRef();
  let [color] = useState("#ffa500");

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/") && file.size <= 5000000) {
      setProfileImage(URL.createObjectURL(file));
      setImageFile(file);
    } else {
      toast.error("Please upload a valid image file (Max 5MB).");
    }
  };

  const getVendorDetails = () => {
    setLoading(true);
    axios
      .get(`${config.apiBaseUrl}/vendors/getvendordetails`)
      .then((response) => {
        setVendorDetails(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Failed to fetch vendor details.");
        setLoading(false);
        console.error("There was an error fetching the vendor details!", error);
      });
  };

  useEffect(() => {
    const fetchVendorTypes = async () => {
      try {
        const response = await axios.get(
          `${config.apiBaseUrl}/vendors/getVendorType`
        );
        setVendorTypes(response.data.vendor_types);
      } catch (err) {
        console.log(
          err.response?.data?.message || "An unexpected error occurred"
        );
      }
    };

    fetchVendorTypes();
  }, []);

  const handleSave = async () => {
    if (!name) {
      toast.error("Name is required.");
      return;
    }
    if (!phoneNumber) {
      toast.error("Phone Number is required.");
      return;
    }
    if (!type) {
      toast.error("Type is required.");
      return;
    }

    setSaving(true);
    const formData = new FormData();
    formData.append("Type", type);
    formData.append("Vendor_Name", name);
    formData.append("Mobile_Number", phoneNumber);
    if (imageFile) {
      formData.append("file", imageFile);
    } else if (editVendor?.photo) {
      formData.append("photo", editVendor.photo);
    }

    try {
      let url = `${config.apiBaseUrl}/vendors/insert_vendor`;
      let method = "POST";

      if (editVendor) {
        url = `${config.apiBaseUrl}/vendors/update_vendor/${editVendor.Vendor_id}`;
        method = "PUT";
      }

      const response = await fetch(url, {
        method,
        body: formData,
      });
      const result = await response.json();
      if (result.message.includes("Mobile Number already exists")) {
        toast.error("Mobile number already exists. Please use a different number.");
      } else if (response.ok && result.status === "success") {
        toast.success(  editVendor ? "Vendor updated successfully!" : "Vendor added successfully!"   );
        setName("");
        setPhoneNumber("");
        setType("");
        setProfileImage(null);
        setImageFile(null);
        setAddManagerOpen(false);
        setEditVendor(null);
        getVendorDetails();
      } else {
        toast.error("Failed to save the vendor.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while saving the vendor.");
    } finally {
      setSaving(false);
    }
  };

  const handleClickOutside = (event) => {
    if (chefRef.current && !chefRef.current.contains(event.target)) {
      setAddManagerOpen(false);
      setEditVendor(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    getVendorDetails();
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAddOpen = () => {
    setAddManagerOpen(true);
    setEditVendor(null);
    setName("");
    setPhoneNumber("");
    setType("");
    setProfileImage(null);
    setImageFile(null);
  };

  const handleEditVendor = (vendor) => {
    setAddManagerOpen(true);
    setEditVendor(vendor);
    setName(vendor.Vendor_Name);
    setPhoneNumber(vendor.Mobile_Number);
    setType(vendor.Type);
    setProfileImage(vendor.Photo || uplode);
  };

  const handleVendorCardOpen = (index) => {
    setActiveCard(activeCard === index ? null : index);
  };

  const deleteEmployee = (Vendor_id) => {
    axios
      .delete(`${config.apiBaseUrl}/vendors/delete_vendor/${Vendor_id}`)
      .then((response) => {
        toast.success(response.data.message);
        setVendorDetails((prevDetails) =>
          prevDetails.filter((vendor) => vendor.Vendor_id !== Vendor_id)
        );
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message || error.response.data.error);
        } else {
          toast.error("An error occurred while deleting the vendor.");
        }
      });
  };

  return (
    <div className="vendor">
      <ToastContainer />
      <p className="vendor-textsmall">Vendors</p>
      <div className="d-flex justify-content-end">
        <button onClick={handleAddOpen} className="Addvendors">
          {editVendor ? "Edit Vendor" : "Add New"}
        </button>
      </div>
      {addManagerOpen && (
        <div className="popup-overlay">
          <div ref={chefRef} className="manager-content">
            <h4 className="Team-management">
              {editVendor ? "Edit Vendor" : "Add Vendor"}
            </h4>
            <form className="sheulde-orderform mt-4">
              <div className="d-flex justify-content-center">
                <label htmlFor="imageUpload" className="imageLabel">
                  {profileImage ? (
                    <img src={profileImage} alt="Profile" className="image" />
                  ) : (
                    <div className="placeholderteam">
                      <span>
                        <img className="uploadIcon" src={uplode} alt="" />
                      </span>
                    </div>
                  )}
                </label>
                <input
                  type="file"
                  id="imageUpload"
                  className="imageUploadInput"
                  onChange={handleImageUpload}
                />
              </div>
              <label className="form-label">Name</label>
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className="form-control"
              />
              <label className="form-label">Phone Number</label>
              <input
                type="text"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
                className="form-control"
              />
              <label className="form-label">Type</label>
              <select
                id="vendorType"
                className="form-control"
                value={type}
                onChange={(e) => setType(e.target.value)}
                required
              >
                <option value="" disabled>
                  Select Type
                </option>
                {vendorTypes.map((vendor) => (
                    <option key={vendor.Vendor_id} className="text-dark" value={vendor.Vendor_id}>
                      {vendor.type}
                    </option>
                  ))}
              </select>
              <button
                type="button"
                onClick={handleSave}
                className="Team-managementbtn"
                disabled={saving} 
              >
                {saving ? "Saving..." : editVendor ? "Update" : "Save"}
              </button>
            </form>
          </div>
        </div>
      )}
      <div className="row mt-4">
        {loading ? (
          <div className="spinner-container">
            <div className="sweet-loading">
              <PulseLoader
                color={color}
                loading={loading}
                size={12}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          </div>
        ) : vendorDetails.length > 0 ? (
          vendorDetails.map((vendor, index) => (
            <div key={index} className="col-sm-6 col-12 col-lg-3 p-2">
              <div className="card manager-backgroundimg">
                <h6 className="text-end iconelips">
                  <FontAwesomeIcon
                    onClick={() => handleVendorCardOpen(index)}
                    icon={faEllipsis}
                  />
                </h6>

                {activeCard === index && (
                  <div className="vendorscardoption">
                    <p
                      className="actionvendoredit"
                      onClick={() => handleEditVendor(vendor)}
                    >
                      Edit
                    </p>
                    <p
                      onClick={() => deleteEmployee(vendor.Vendor_id)}
                      className="actionvendordelete"
                    >
                      Delete
                    </p>
                  </div>
                )}

                <div className="card-bodyvendors">
                  <div className="vendorsprofile">
                    <img
                      src={
                        vendor.Photo && vendor.Photo.trim() !== ""
                          ? vendor.Photo
                          : defaultImage
                      }
                      className="vendorsprofile-img"
                      alt="Vendor"
                    />
                  </div>
                  <h2 className="vendorheadtext pt-1">{vendor.Vendor_Name}</h2>
                  <p className="vendors-textsmall">
                    {vendor.Type} Vendor
                    <br />
                    {vendor.Mobile_Number}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No vendors available.</p>
        )}
      </div>
    </div>
  );
};
