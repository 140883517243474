import React, { useState, useEffect } from "react";
import "../style/addish.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../config/config";
import axios from "axios";

function Addfixedmenus() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [dishname, setDishname] = useState("");
  const [selectedFoodItems, setSelectedFoodItems] = useState([]);
 

  useEffect(() => {
    axios
      .get(`${config.apiBaseUrl}/menus/get_all_web_menu_details`)
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error("Error fetching the data", error);
        toast.error("Error fetching menu details, please try again later.");
      });
  }, []);

  const handleCategoryClick = (categoryId) => {
    if (selectedCategory === categoryId) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(categoryId);
    }
  };

  // Track selected food items
  const handleCheckboxChange = (menuId) => {
    setSelectedFoodItems((prevSelected) => {
      if (prevSelected.includes(menuId)) {
        return prevSelected.filter((id) => id !== menuId);
      } else {
        return [...prevSelected, menuId];
      }
    });
  };

  const handleMenuSave = () => {
    if (dishname.trim() === "") {
      alert("Menu name cannot be empty");
      return;
    }

    fetch(`${config.apiBaseUrl}/menus/insert_fixedmenus/${dishname}`, {
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Menu Name already Exists") {
          toast.error("Menu name already exists, please choose a different name");
        } else {
          toast.success("Fixed menu created successfully");
          setDishname("");
          handleInsert(data.menu_id); // Pass the created menu_id to insert food items
        }
      })
      .catch((error) => {
        toast.error("An error occurred while creating the menu");
      });
  };

  const handleInsert = async (menu_id) => {
    try {
      const response = await axios.post(`${config.apiBaseUrl}/menus/insert_fixedmneu_fooditems`, {
        fooditems: selectedFoodItems,
        foodid: menu_id,
      });

      if (response.status === 201) {
        toast.success("Food items inserted successfully");
        setSelectedFoodItems([]); 
      }
    } catch (error) {
      if (error.response) {
        toast(error.response.data.error || 'An unexpected error occurred');
      } else {
        toast('Server is not responding. Please try again later.');
      }
    }
  };

  return (
    <div className="menus">
      <ToastContainer />
      <h5 className="menuheadx mt-3"> Add Fixed Menus</h5>
      <div className="dishdivchoose">
        <h4 className="menuheadx text-center mt-2">Add Fixed New Menus</h4>
        <div className="row d-flex justify-content-center mt-3">
          <div className="col-lg-2 col-md-1 col-sm-0 col-0"></div>
          <div className="col-8 col-md-8 col-sm-12 col-12">
            <input
              type="text"
              value={dishname}
              onChange={(e) => setDishname(e.target.value)}
              placeholder="Enter New Fixed Menus Name"
              className="form-group-input"
            />
          </div>
          <div className="col-lg-2  col-md-1 col-sm-0 col-0"></div>
        </div>
        <div className="row mt-4 d-flex justify-content-center">
          {categories.map((category) => (
            <div key={category.category_Id} className="col-lg-12 col-12 mt-2">
              <div
                className="beavergecatagry"
                onClick={() => handleCategoryClick(category.category_Id)}
              >
                <div className="row">
                  <div className="col-lg-11 col-md-11 col-11">
                    <h6 className="catagaryname">{category.category_name}</h6>
                  </div>
                  <div className="col-lg-1 col-md-1 col-sm-1 col-1 d-flex justify-content-end chevronbutton">
                    <FontAwesomeIcon
                      className="chevronmenusicon"
                      icon={faChevronDown}
                    />
                  </div>
                </div>
              </div>
              {selectedCategory === category.category_Id && (
                <div
                  className={`row subdivfixedmenu${
                    selectedCategory === category.category_Id ? "show" : ""
                  }`}
                >
                  {category.sub_categories.map((subCategory) => (
                    <div
                      key={subCategory.sub_category_id}
                      className="sub-catagry"
                    > 
                      <h5 className="sub-catagry-name">
                        {subCategory.sub_category_name}
                      </h5>
                      <div className="row">
                        {subCategory.menus.map((menu) => (
                          <div
                            key={menu.Menu_Id}
                            className="col-lg-3 col-md-3 col-sm-4 col-6 mb-2"
                          >
                            <input
                              className="form-check-input checbox"
                              type="checkbox"
                              checked={selectedFoodItems.includes(menu.Menu_Name)}
                              onChange={() => handleCheckboxChange(menu.Menu_Name)}
                            />
                            {menu.Menu_Name}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="button-groupadd">
          <button className="insert-name-savebtn" onClick={handleMenuSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default Addfixedmenus;
